import {Getter} from './types.js'

export default {
    // second arg is "getters" (other getters)
    [Getter.GetSignUpSchema](state) {
        return state.signUpSchema
    },
    [Getter.GetSubmittedResponse](state) {
        return state.submittedFormResponse
    },
    [Getter.GetCompleted](state) {
        return state.completed
    },
    [Getter.GetSavedSignUpForm](state) {
        return state.submittedForm
    },
    [Getter.GetSuccessful](state) {
        // true only when response is 200
        if (state.completed) {
            if (state.submittedFormResponse.status === 200) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
}
