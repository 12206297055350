import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
//import _ from 'lodash';

import {getBaseURL} from '@/store/helpers'


// from https://stackoverflow.com/questions/43482639/throttling-axios-requests
// function scheduleRequests(axiosInstance, intervalMs) {
//     let lastInvocationTime = undefined;
//
//     const scheduler = (config) => {
//         const now = Date.now();
//         if (lastInvocationTime) {
//             lastInvocationTime += intervalMs;
//             const waitPeriodForThisRequest = lastInvocationTime - now;
//             if (waitPeriodForThisRequest > 0) {
//                 return new Promise((resolve) => {
//                     setTimeout(
//                         () => resolve(config),
//                         waitPeriodForThisRequest);
//                 });
//             }
//         }
//
//         lastInvocationTime = now;
//         return config;
//     }
//
//     axiosInstance.interceptors.request.use(scheduler);
// }

Vue.use(Vuex);
Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = getBaseURL("/__api__/model/");

import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
    key: 'budget-icu',
    storage: window.localStorage,
    // only store some things in local storage
    reducer: (state) => {
        return {
            profile: state.profile, // the profile module
        }
    },
})

// TODO: this throttle is global...
// TODO: make this easier to configure...?
// TODO: figure out a way to scope to certain API requests? not all need to be throttled...?
// scheduleRequests(Vue.axios, 900)

import account from './account';
import anonymous from './anonymous';
import budgetCategory from './budgetCategory'
import budgetTransact from './budgetTransact';
import importHelper from './importHelper'
import instance from './instance'
import openapi from './openapi'
import profile from './profile'
import session from './session'
import transact from './transact';
import summary from './summary';

export default new Vuex.Store({
    modules: {
        account,
        anonymous,
        budgetCategory,
        budgetTransact,
        importHelper,
        instance,
        openapi,
        profile,
        session,
        transact,
        summary,
    },
  state: {},
  mutations: {},
  actions: {},
  plugins: [
      vuexPersist.plugin
  ]
})
