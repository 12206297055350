import Vue from 'vue';
import {Mutation} from './types';
export default {
    [Mutation.SaveActiveInstance](state, payload) {
        Vue.set(state, 'activeInstance', payload)
    },
    [Mutation.SaveMonthlyInstanceList](state, payload) {
        Vue.set(state, 'monthlyInstanceList', payload)
    },
}
