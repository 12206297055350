import {Mutation} from "./types";
import Vue from 'vue';

export default {
    [Mutation.saveFinpicProfile](state, {newData, altData}) {
        Vue.set(state, 'finpicProfile', newData)
        Vue.set(state, 'finpicProfileAlt', altData)
    },
    [Mutation.saveUserProfile](state, newdata) {
        Vue.set(state, 'userProfile', newdata)
    },
    [Mutation.saveActiveFinpic](state, newData) {
        Vue.set(state, 'activeFinpic', newData)
    },
    [Mutation.saveFinpicList](state, newData) {
        Vue.set(state, 'finpicList', newData)
    },
}
