import Vue from 'vue';
import {Action, Mutation} from './types'
import {authHeader} from "@/store/headers";
import {getBaseURL} from "@/store/helpers";
import {Action as TransactAction} from '../transact/types'

function createUploadFileRecordTransact(uploadfilerecordid, transactid) {
    let submitData = {
        transactid: transactid,
        uploadfilerecordid: uploadfilerecordid,
    }
    return new Promise(
        (resolve) => {
            // TODO: why is this throttle not global...?
            // TODO: make this easier to configure...?
            Vue.axios.post(
                '/uploadfilerecordtransact',
                submitData,
                {
                    headers: authHeader(),
                }
            ).then(
                () => {resolve()}
            )
        })
}

export default {
    [Action.FetchUploadFiles]({commit}) {
        Vue.axios.get(
            // TODO: need to fix this join so it does not show up as an array
            '/uploadfile?select=*,uploadfilemeta(*)',
            {
                headers: {...authHeader()}
            }).then(
                result => {
                    commit(`${[Mutation.SaveUploadFiles]}`, result.data)
                }
        ).catch(
            error => {
                throw new Error(`API ${error}`)
            }
        )
    },
    [Action.UploadFile]({commit}, {filename, file}) {
        let formData = new FormData()
        formData.append(filename, file)

        return new Promise((resolve) => {
            return Vue.axios.post(
                '/upload',
                formData,
                {
                    headers: {
                        ...authHeader(),
                        'Content-Type': 'multipart/form-data'
                    },
                    baseURL: getBaseURL('/__api__/import/')
                }
            ).then(
                () => {
                    commit(`${[Mutation.UploadFileSuccess]}`, true)
                    resolve()
                }
            ).catch(
                error => {throw new Error(`API ${error}`)}
            )
        })
    },
    [Action.GetMatchOptions]({commit}) {
        return new Promise((resolve) => {
            return Vue.axios.get(
                '/match/options',
                {
                    headers: authHeader(),
                    baseURL: getBaseURL('/__api__/import/'),
                }
                ).then(
                    result => {
                        commit(`${[Mutation.SaveMatchOptions]}`, result.data)
                        resolve()
                    }
            )
        })
    },
    [Action.CheckUploadFileMatches]({commit}, {uploadfileid, accountid, columns, matchoptions, minmatch = null, maxmatch = null,}) {
        commit(`${[Mutation.SetUploadFileMatchRunning]}`, true)
        let submitData = {
            uploadfileid: uploadfileid,
            accountid: accountid,
            columns: columns,
            matchoptions: matchoptions,
            minmatch: minmatch,
            maxmatch: maxmatch,
        }
        if (!submitData['minmatch']) delete submitData['minmatch']
        if (!submitData['maxmatch']) delete submitData['maxmatch']
        return new Promise((resolve) => {
            return Vue.axios.post(
                '/match/compute',
                submitData,
                {
                    headers: authHeader(),
                    baseURL: getBaseURL('/__api__/import/'),
                }
            ).then(
                result => {
                    commit(`${[Mutation.SaveUploadFileMatches]}`, {'result': result.data, 'uploadfileid': uploadfileid, 'accountid': accountid})
                    commit(`${[Mutation.SetUploadFileMatchRunning]}`, false)
                    resolve()
                }
            ).catch((error) => {
                commit(`${[Mutation.SetUploadFileMatchRunning]}`, false)
                throw new Error(`API ${error}`)
            })
        })
    },
    [Action.MatchUploadFileRecords]({commit, dispatch}, {matchArray}) {
        // TODO: should figure out a way to do a proper progress bar here...
        // i.e. show progress against number of records to create / etc.
        commit(`${[Mutation.SetMatchUploadFileRecordsRunning]}`, true)
        // matchArray: [matches]
        // match: {transactid (optional), uploadfilerecordid, name, description, date, fromAccount, toAccount, amount, datePost}
        console.log(matchArray)
        return new Promise(
            (resolve) => {
                return Promise.all(
                    matchArray.map(
                    (match) => {
                        if (!match.transactid) {
                            // create the transact
                            let submitData = {
                                name: match.name,
                                description: match.description,
                                date: match.date,
                                fromAccount: match.fromAccount,
                                toAccount: match.toAccount,
                                amount: match.amount,
                                datePost: match.datePost
                            }
                            let tmp = dispatch(`${[TransactAction.UpsertTransact]}`, submitData)

                            console.log(tmp)

                            return tmp.then(
                                (result) => {
                                    console.log("firing follow up!")
                                    console.log(result)
                                    return createUploadFileRecordTransact(
                                        match.uploadfilerecordid,
                                        result,
                                    )
                                }
                            )
                        } else {
                            return createUploadFileRecordTransact(
                                match.uploadfilerecordid,
                                match.transactid,
                            )
                        }
                }
            )).then(
                () => {
                    commit(`${[Mutation.SetMatchUploadFileRecordsRunning]}`, false)
                    resolve()
                }
            ).catch(
                () => {
                    commit(`${[Mutation.SetMatchUploadFileRecordsRunning]}`, false)
                    resolve()
                }
            )
        })
    },
    [Action.DiscardMatch]({commit}, {uploadfilerecordtransactid, transactid}) {
        commit(`${[Mutation.SetDiscardMatchRunning]}`, true)
        return new Promise((resolve) => {
            if (uploadfilerecordtransactid && transactid) {
                Vue.axios.delete(
                    '/uploadfilerecordtransact?uploadfilerecordtransactid=eq.' + uploadfilerecordtransactid + '&transactid=eq.' + transactid,
                    {headers: authHeader()}
                ).then(
                    (res) => {
                        commit(`${[Mutation.SetDiscardMatchRunning]}`, false)
                        resolve(res)
                    }
                ).catch((err) => {
                    console.log(err)
                    resolve(err)
                })
            } else {
                console.log('Missing identifier provided to DiscardMatch. Doing nothing')
                resolve()
            }
        })
    },
}
