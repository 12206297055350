export default {
    savingCategories: [],
    globalInstance: {
        effective: null,
        finpicid: null,
        instanceid: null,
        name: null,
        statusid: null,
        terminate: null,
    },
    savingBudget: {
        budgetid: null,
        budgettypeid: null,
        description: null,
        finpicid: null,
        name: null,
    },
    monthlyCategoryList: [],
}
