export const Action = {
    FetchUploadFiles: "FetchUpoadFiles",
    UploadFile: "UploadFile",
    FetchUploadFileRecords: "FetchUploadFileRecords",
    // TODO: maybe this replaces the above?
    CheckUploadFileMatches: "CheckUploadFileMatches",
    GetMatchOptions: "GetMatchOptions",
    MatchUploadFileRecords: "MatchUploadFileRecords",
    DiscardMatch: "DiscardMatch",
}

export const Mutation = {
    SaveUploadFiles: "SaveUploadFiles",
    UploadFileSuccess: "UploadFileSuccess",
    SetUploadFileMatchRunning: "SetUploadFileMatchRunning",
    SaveUploadFileMatches: "SaveUploadFileMatches",
    SetMatchUploadFileRecordsRunning: "SetMatchUploadFileRecordsRunning",
    SaveMatchOptions: "SaveMatchOptions",
    ActiveUploadFile: "ActiveUploadFile",
    SetDiscardMatchRunning: "SetDiscardMatchRunning",
}

export const Getter = {
    GetUploadFiles: "GetUploadFiles",
    GetMatchOptions: "GetMatchOptions",
    GetUploadFileMatchRunning: "GetUploadFileMatchRunning",
    GetMatchUploadFileRecordsRunning: "GetMatchUploadFileRecordsRunning",
    GetMatches: "GetMatches",
    GetActiveUploadFileId: "GetActiveUploadFileId",
    GetActiveUploadFile: "GetActiveUploadFile",
    GetActiveAccountId: "GetActiveAccountId",
    GetDiscardMatchRunning: "GetDiscardMatchRunning",
}
