import Vue from 'vue'
import {Mutation} from './types.js'

export default {
    [Mutation.SaveUploadFiles](state, payload) {
        Vue.set(state, 'uploadFiles', payload)
    },
    [Mutation.UploadFileSuccess](state, payload) {
        Vue.set(state, 'uploadFileSuccess', payload)
    },
    [Mutation.SaveMatchOptions](state, payload) {
        Vue.set(state, 'matchOptions', payload)
    },
    [Mutation.SetMatchUploadFileRecordsRunning](state, payload) {
        Vue.set(state, 'matchUploadFileRecordsRunning', payload)
    },
    [Mutation.SaveUploadFileMatches](state, payload) {
        if (payload) {
            Vue.set(state, 'matches', payload.result)
            Vue.set(state, 'activeUploadFileId', payload.uploadfileid)
            Vue.set(state, 'activeAccountId', payload.accountid)
        }
    },
    [Mutation.SetUploadFileMatchRunning](state, payload) {
        Vue.set(state, "uploadFileMatchRunning", payload)
    },
}
