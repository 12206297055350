import {Getter} from './types.js'
export default {
    [Getter.getAccountList](state) {
        return state.accountList
    },
    [Getter.getBankList](state) {
        return state.bankList
    },
    [Getter.getAccountTypeList](state) {
        return state.accountTypeList
    }
}
