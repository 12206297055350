export const Action = {
    FetchSummary: 'FetchSummary',
    FetchMonthSummary: 'FetchMonthSummary',
    FetchBudgetCategoryData: 'FetchBudgetCategoryData',
}

export const Mutation = {
    SetSummary: 'SetSummary',
    SetMonthSummary: 'SetMonthSummary',
    SetMonthSummaryRunning: 'SetMonthSummaryRunning',
    SetBudgetCategoryData: 'SetBudgetCategoryData',
    SetBudgetCategoryDataRunning: 'SetBudgetCategoryDataRunning',
}

export const Getter = {
    GetSummary: 'GetSummary',
    GetMonthSummary: 'GetMonthSummary',
    GetMonthSummaryRunning: 'GetMonthSummaryRunning',
    GetBudgetCategoryData: 'GetBudgetCategoryData',
    GetBudgetCategoryDataRunning: 'GetBudgetCategoryDataRunning',
}
