<template>
  <v-container>
    <v-row class="text-center">
      <!-- TODO: Logo
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
      -->

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Budget ICU
        </h1>

        <p class="subheading font-weight-regular">
          A place to help you "see" your Budget
          <br> or start intensive care
        </p>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h1 class="mb-3">
          Get Started
        </h1>

        <v-row justify="center">
          <router-link
            v-for="(item, i) in getStarted"
            :key="i"
            :to="item.href"
            class="subheading mx-3"
          >
            {{ item.text }}
          </router-link>
        </v-row>
      </v-col>

    </v-row>

    <br />
    <v-row>
      <v-spacer />
      <h1>Tools</h1>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col cols="0" md="3"></v-col>
      <v-col cols="12" md="6">
        <ActionButtons
          :links=links itemHeight="100px"
          cardHeight="150px"
          type="icon"
          />
      </v-col>
      <v-col cols="0" md="3"></v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ActionButtons from '@/components/ActionButtons.vue';
  export default {
    name: 'HomePage',
    components: {
      ActionButtons,
    },
    data: () => ({
      getStarted: [
        {
          text: 'Set up your accounts',
          href: '/account',
        },
        {
          text: 'Set up your budget',
          href: '/budget',
        },
        {
          text: 'Import your first statement',
          href: '/import',
        },
      ],
      links: [
        {
          title: 'Loan Calculator',
          cols: 6,
          icon: 'mdi-calculator',
          url: '/tools/loan',
        },
        {
          title: 'Amortize',
          cols: 6,
          icon: 'mdi-calendar-month',
          url: '/tools/amortize',
        },
        {
          title: 'Save',
          cols: 6,
          icon: 'mdi-piggy-bank',
          url: '/tools/invest',
        },
        {
          title: 'Learn',
          cols: 6,
          icon: 'mdi-book-open-page-variant',
          url: '/learn',
        },
      ],
    }),
  }
</script>
