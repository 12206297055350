import Vue from 'vue';
import {Mutation} from './types';
export default {
    [Mutation.SetSummary](state, payload) {
        Vue.set(state, 'summary', payload)
    },
    [Mutation.SetMonthSummary](state, payload) {
        Vue.set(state, 'monthSummary', payload)
    },
    [Mutation.SetMonthSummaryRunning](state, payload) {
        Vue.set(state, 'monthSummaryRunning', payload)
    },
    [Mutation.SetBudgetCategoryData](state, payload) {
        Vue.set(state, 'budgetCategoryData', payload)
    },
    [Mutation.SetBudgetCategoryDataRunning](state, payload) {
        Vue.set(state, 'budgetCategoryDataRunning', payload)
    },
}
