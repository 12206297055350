import {Getter} from './types'

export default {
    [Getter.GetBudgetTransactList](state) {
        return state.budgetTransactList
    },
    [Getter.GetBudgetTransactListPosNeg](state) {
        let dat = state.budgetTransactList

        // if fromtransactdetaillink exists, this should be negative
        dat = dat.map(
            (item) => {
                if (item.fromtransactdetaillink && item.fromtransactdetaillink.length > 0) {
                    if (item.totransactdetaillink && item.totransactdetaillink.length > 0) {
                        // TODO: ERROR. Both cannot be true...
                    } else {
                        item.amount = -1 * item.amount
                    }
                }
                return item
            }
        )
        return dat
    },
    [Getter.GetDistinctBudgetTransactTags](state) {
        return state.distinctBudgetTransactTags
    },
}
