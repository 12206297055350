import Vue from 'vue'
import {Action, Mutation} from './types.js'
// import {getBaseURL} from "@/store/helpers";

// TODO: it is not possible to use `getBaseURL` with the anonymous / signup behavior
// it will always point at the same domain where it is deployed, today

export default {
    [Action.LoadSignUpSchema]({commit}) {
        Vue.axios.get(
            'schema/budget-icu',
            {
                baseURL: '/__api__/signup/'
            }
        ).then(
            result => {
                commit(`${[Mutation.SaveSignUpSchema]}`, result)
            }
        )
    },
    [Action.SaveSignUpForm]({commit}, payload) {
        return Vue.axios.post(
            'data',
            {"key": "budget-icu", "payload": payload},
            {
                baseURL: '/__api__/signup/'
            }
        ).then(
            result => {
                commit(`${[Mutation.SaveSignUpForm]}`, {result: result, payload: payload})
            }
        )
    },
    [Action.ClearSubmittedResponse]({commit}) {
        commit(`${[Mutation.ClearSubmittedResponse]}`)
    },
}
