import {Getter} from './types.js'

export default {
    [Getter.GetUploadFiles](state) {
        return state.uploadFiles
    },
    [Getter.GetMatchOptions](state) {
        return state.matchOptions
    },
    [Getter.GetMatchUploadFileRecordsRunning](state) {
        return state.matchUploadFileRecordsRunning
    },
    [Getter.GetMatches](state) {
        return state.matches
    },
    [Getter.GetActiveUploadFile](state) {
        if (state.uploadFiles) {
            let activeUploadFile = state.uploadFiles.filter(
                (item) => {return item.uploadfileid === state.activeUploadFileId}
            )
            console.log(activeUploadFile)
            return activeUploadFile[0]
        }
        return {}
    },
    [Getter.GetActiveUploadFileId](state) {
        return state.activeUploadFileId
    },
    [Getter.GetActiveAccountId](state) {
        return state.activeAccountId
    },
    [Getter.GetUploadFileMatchRunning](state) {
        return state.uploadFileMatchRunning
    },
}
