import {Action, Mutation} from './types'
import {Action as InstanceAction} from '../instance/types'
import {Getter as ProfileGetter} from '../profile/types'
import Vue from "vue";
import {authHeader} from "@/store/headers";
import {renameAllKeys} from "@/store/helpers";


export default {
    [Action.FetchSavingCategories]({commit}) {
        Vue.axios.get(
            'detail?select=*,detailtype!fk_detail_finpic_detailtype(*,detailgroup(*))',
            {
              headers: authHeader()
            }
            ).then(
              result => {
                  let data = result.data

                  // only keep "savings" type categories
                  data = data.filter(
                      (elt) => {
                          return elt.detailtype.detailgroup.detailgrouptypeid === 2
                      }
                  )
                  data = renameAllKeys(data)

                  data = data.map(
                      (x) => {
                          return {
                              budgetcategoryinstanceid: x.budgetcategoryinstanceid,
                              budgetcategoryid: x.budgetcategoryid,
                              name: x.budgetcategory.name,
                              description: x.budgetcategory.description,
                              amount: x.amount,
                              budget: x.budgetcategory.budget,
                              instance: x.instance,
                              income: x.budgetcategory.income
                          }
                      }
                  )

                  commit(`${[Mutation.SaveSavingCategories]}`, data)
              }
        )
    },
    [Action.FetchGlobalInstance]({commit}) {
        Vue.axios.get(
            'detailinstance?name=eq.Global',
            {headers: authHeader()},
            // TODO: get just the first instance...?
        ).then(
            result => {
                let data = result.data

                data = renameAllKeys(data)
                commit(`${[Mutation.SaveGlobalInstance]}`, data[0])
            }
        )
    },
    [Action.FetchSavingBudget]({commit, getters}) {
        const finpic = getters[`${[ProfileGetter.getActiveFinpic]}`]
        const finpicid = finpic.finpicid
        Vue.axios.get(
            'detailgroup?name=eq.Saving&finpicid=eq.' + finpicid,
            {headers: authHeader()},
            // TODO: get just the first instance...?
        ).then(
            result => {
                let data = result.data
                data = renameAllKeys(data)
                commit(`${[Mutation.SaveSavingBudget]}`, data[0])
            }
        )
    },
    [Action.FetchMonthlyCategoryList]({commit, dispatch, getters}, {instanceid, instancename}) {
        const finpic = getters[`${[ProfileGetter.getActiveFinpic]}`]
        const finpicid = finpic.finpicid
        Promise.all([
            Vue.axios.get('detailtype?finpicid=eq.' + finpicid + '&select=*,detailgroup(*)', {headers: authHeader(),}),
            Vue.axios.get('detail?finpicid=eq.' + finpicid + '&select=*,detailtype!fk_detail_finpic_detailtype(*,detailgroup(*)),detailinstance(*)', {headers: authHeader(),}),
        ]).then(
            (results) => {
                if (instanceid || instancename) {
                    dispatch(`${[InstanceAction.SetActiveInstance]}`, {instanceid: instanceid, instancename: instancename})
                }
                const rawCategories = renameAllKeys(
                    results[0].data.filter(
                        (x) => {
                            return x.detailgroup.detailgrouptypeid === 1
                        }
                    ).map(
                        (x) => {
                            x.amount = null;
                            return x
                        }
                    )
                )
                const rawCategoryInstances = renameAllKeys(
                    results[1].data.filter(
                        (x) => {
                            let prefilter = true;
                            if (instanceid) {
                                prefilter = prefilter && x.detailinstanceid === instanceid
                            }
                            if (instancename) {
                                prefilter = prefilter && x.detailinstance.name === instancename
                            }
                            return prefilter && x.detailtype.detailgroup.detailgrouptypeid === 1
                        }
                    ).map(
                        // protect against naming order issues
                        (x) => {
                            x.amount = x.budget;
                            return x;
                        }
                    )
                )

                const finalCategories = [].concat(
                    rawCategories.filter(
                        (x) => {
                            return rawCategoryInstances.findIndex((y) => y.budgetcategoryid === x.budgetcategoryid) === -1
                        }
                    ).map(
                        (x) => {
                            return {
                                budgetcategoryinstanceid: null,
                                budgetcategoryid: x.budgetcategoryid, name: x.name,
                                description: x.description,
                                amount: null, budget: x.budget, instance: null, income: x.income
                            }
                        }
                    ),
                    rawCategoryInstances.map(
                        (x) => {
                            return {
                                budgetcategoryinstanceid: x.budgetcategoryinstanceid,
                                budgetcategoryid: x.budgetcategoryid,
                                name: x.budgetcategory.name,
                                description: x.budgetcategory.description,
                                amount: x.amount,
                                budget: x.budgetcategory.budget,
                                instance: x.instance,
                                income: x.budgetcategory.income
                            }
                        }
                    )
                )

                commit(`${[Mutation.SaveMonthlyCategoryList]}`, finalCategories)
            }
        )
    },
    [Action.DeleteMonthlyCategoryInstance](_, {budgetcategoryinstanceid}) {
        if (!budgetcategoryinstanceid) {
            throw new Error(`Error deleting budgetcategoryinstance - budgetcategoryinstanceid is not defined`)
        }
        console.log('Deleting ' + budgetcategoryinstanceid)
        return new Promise(
            (resolve) => {
                Vue.axios.delete(
                    'detail?detailid=eq.' + budgetcategoryinstanceid,
                    {headers: authHeader(),}
                ).then(() => {
                    resolve()
                }).catch(error => {
                    throw new Error(`API ${error}`)
                })
            }
        )
    },
    [Action.UpsertMonthlyCategoryInstance](_, {
        finpicid, budgetcategoryid, budgetcategoryinstanceid,
        budgetid, instanceid,
        name, amount, description, income
    }) {
        const submitObject = {
            finpicid: finpicid,
            name: name,
            amount: amount,
            income: income,
            description: description,
            budgetid: budgetid,
            budgetcategoryid: budgetcategoryid,
            budgetcategoryinstanceid: budgetcategoryinstanceid,
            instanceid: instanceid,
        }
        return new Promise((resolve) => {
            Vue.axios.post('/rpc/upsert_category_instance_v1', submitObject, {
                headers: authHeader(),
            }).then(
                () => {
                    resolve()
                }
            ).catch(error => {
                throw new Error(`API ${error}`)
            })
        })
    },
}
