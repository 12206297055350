import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VueMeta from 'vue-meta';

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/learn',
    name: 'Learn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "learn" */ '../views/Learn.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue')
  },
  {
    path: '/import',
    name: 'Import',
    component: () => import(/* webpackChunkName: "import" */ '../views/Import.vue')
  },
  {
    path: '/review',
    name: 'Review',
    component: () => import(/* webpackChunkName: "review" */ '../views/Review.vue')
  },
  {
    path: '/budget',
    name: 'Budget',
    component: () => import(/* webpackChunkName: "budget" */ '../views/Budget.vue')
  },
  {
    path: '/plan',
    name: 'BudgetCategory',
    component: () => import(/* webpackChunkName: "budgetCategory" */ '../views/BudgetCategory.vue')
  },
  {
    path: '/account',
    name: 'Accounts',
    component: () => import(/* webpackChunkName: "Account" */ '../views/Account.vue')
  },
  {
    path: '/explore',
    name: 'Explore',
    component: () => import(/* webpackChunkName: "Account" */ '../views/Explorer.vue')
  },
  {
    path: '/save',
    name: 'Savings',
    component: () => import(/* webpackChunkName: "OtherCategory" */ '../views/OtherCategory.vue')
  },
  {
    path: '/summary',
    name: 'Summary',
    component: () => import(/* webpackChunkName: "Summary" */ '../views/Summary.vue')
  },
  {
    path: '/swagger',
    name: 'Swagger',
    component: () => import(/* webpackChunkName: "Swagger" */ '../views/Swagger.vue')
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import(/* webpackChunkName: "Tools" */ '../views/Tools.vue'),
      redirect: '/tools/loan',
    children: [
     // {
     //   path: "",
     //   component: () => import(/* webpackChunkName: "Loan" */ '../components/Loan.vue'),
     // },
      {
        path: "loan",
        component: () => import(/* webpackChunkName: "Loan" */ '../components/Loan.vue'),
      },
      {
        path: "amortize",
        component: () => import(/* webpackChunkName: "Amortize" */ '../components/Amortize.vue'),
      },
      {
        path: "invest",
        component: () => import(/* webpackChunkName: "Invest" */ '../components/Invest.vue'),
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (/*to, from, savedPosition*/) {
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html#async-scrolling
      return {x: 0, y:0}
  }
})

router.beforeEach(
    (to, from, next) => {
        const publicPages = ['/login', '/about', '/signup', '/home', '/', '/tools/', '/tools/loan', '/tools/amortize', '/tools/invest', '/learn'];
        const authRequired = !publicPages.includes(to.path);
        const loggedIn = localStorage.getItem('user');

        // redirect to login page if not logged in...
        if (authRequired && !loggedIn) {
          next({path: '/login', query: {redirect: to.path}})
        } else {
          next();
        }
    }
)

export default router
