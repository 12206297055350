export const Action = {
    BudgetTransactList: "BudgetTransactList",
    UpsertBudgetTransact: "UpsertBudgetTransact",
    FetchDistinctBudgetTransactTags: "FetchDistinctBudgetTransactTags",
}

export const Mutation = {
    BudgetTransactList: "BudgetTransactList",
    UpsertBudgetTransact: "UpsertBudgetTransact",
    DistinctBudgetTransactTags: "DistinctBudgetTransactTags",
}

export const Getter = {
    GetBudgetTransactList: "GetBudgetTransactList",
    GetBudgetTransactListPosNeg: "GetBudgetTransactListPosNeg",
    GetDistinctBudgetTransactTags: "GetDistinctBudgetTransactTags",
}
