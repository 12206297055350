import {Getter} from './types'
export default {
    [Getter.GetSavingCategories](state) {
        return state.savingCategories;
    },
    [Getter.GetGlobalInstance](state) {
        console.log("GLOBAL INSTANCE")
        console.log(state.globalInstance)
        return state.globalInstance
    },
    [Getter.GetSavingBudget](state) {
        return state.savingBudget
    },
    [Getter.GetMonthlyCategoryList](state) {
        return state.monthlyCategoryList
    },
}
