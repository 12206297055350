import {Getter} from './types'
export default {
    [Getter.getActiveFinpic](state) {
        return state.activeFinpic
    },
    [Getter.getUserProfile](state) {
        return state.userProfile
    },
    [Getter.getFinpicProfile](state) {
        return state.finpicProfile
    },
    [Getter.getFinpicProfileAlt](state) {
        return state.finpicProfileAlt
    },
    [Getter.getFinpicList](state) {
        return state.finpicList
    },
}
