import {Getter} from './types.js'

export default {
    [Getter.GetUpsertTransactRunning](state) {
        return state.upsertTransactRunning
    },
    [Getter.GetPositiveCategoryInstanceList](state) {
        return state.positiveCategoryInstanceList
    },
    [Getter.GetNegativeCategoryInstanceList](state) {
        return state.negativeCategoryInstanceList
    },
    [Getter.GetAllCategoryInstanceList](state) {
        return state.allCategoryInstanceList
    },
    [Getter.GetTransactList](state) {
        return state.transactList
    },
    [Getter.GetTransactListRunning](state) {
        return state.transactListRunning
    },
    [Getter.GetExploreTransactList](state) {
        return state.exploreTransactList
    },
    [Getter.GetExploreTransactListRunning](state) {
        return state.exploreTransactListRunning
    },
    [Getter.GetActiveTransact](state) {
        return state.activeTransact
    },
    [Getter.GetActiveTransactCompleted](state) {
        return state.activeTransactCompleted
    },
    [Getter.GetDistinctTransactTags](state) {
        return state.distinctTransactTags
    },
    [Getter.GetTransactNameList](state) {
        return state.transactNameList
    }
}
