import Vue from 'vue'
import {Action, Mutation} from './types.js'
import {authHeader} from "@/store/headers";
import {renameAllKeys} from "@/store/helpers";

export default {
    [Action.BudgetTransactList]({commit}, {instanceid, uploadfileid}) {
        // TODO: This could be a LOT to fetch...
        // Need to really filter server side...
        Vue.axios.post(
            // 'transactdetail?select=*,transact!fk_transactdetail_transactid(*,uploadfilerecordtransact(*,uploadfilerecord(*,uploadfile(*))),primarytransactattrlink(*, transactattrlink(*, innertransactattr:transactattr!fk_transactattrlink_innertransactattr(*), outertransactattr:transactattr!fk_transactattrlink_outertransactattr(*)))),detail(*,detailtype!fk_detail_finpic_detailtype(*,detailgroup(*)),detailinstance(*)),fromtransactdetaillink:transactdetaillink!fk_transactdetaillink_finpic_transactdetail_from(*),totransactdetaillink:transactdetaillink!fk_transactdetaillink_finpic_transactdetail_to(*)',
            '/rpc/tmp_get_transactdetail',
            {'x_instanceid': instanceid, 'x_uploadfileid': uploadfileid},
            {headers: authHeader()}
        ).then(
            result => {
                let outputData = renameAllKeys(
                    result.data.filter(
                        (bt) => {
                            let keepRecord = true
                            if (instanceid) {
                                keepRecord = keepRecord && bt.detail.detailinstanceid === instanceid
                            }
                            if (uploadfileid) {
                                let anyMatches = false
                                if (bt.transact) {
                                    if (bt.transact.uploadfilerecordtransact) {
                                        anyMatches = bt.transact.uploadfilerecordtransact.some(
                                            (elt) => {
                                                if (elt.uploadfilerecord) {
                                                    return elt.uploadfilerecord.uploadfileid === uploadfileid
                                                }
                                                return false
                                            }
                                        )
                                    }
                                }
                                keepRecord = keepRecord && anyMatches
                            }
                            return keepRecord
                        }
                    )
                )
                commit(`${[Mutation.BudgetTransactList]}`, outputData)
            }
        ).catch(
            error => {throw new Error(`API ${error}`)}
        )
    },
    [Action.UpsertBudgetTransact]({commit}, {transactid, frombudgetcategoryinstanceid, tobudgetcategoryinstanceid, amount, description, finpicid, budgettransactlinkid= null, tags=null}) {
        // force amounts to be negative (from) and positive (to)
        // TODO: enforce server side...?
        let budgetData = {
            fromtransactid: transactid,
            frombudgetcategoryinstanceid: frombudgetcategoryinstanceid,
            fromamount: -1 * Math.abs(amount),
            fromdescription: description,
            totransactid: transactid,
            tobudgetcategoryinstanceid: tobudgetcategoryinstanceid,
            toamount: Math.abs(amount),
            todescription: description,
            finpicid: finpicid,
            budgettransactlinkid: budgettransactlinkid,
            tags: tags,
        }
        if (!budgettransactlinkid) {
            delete budgetData["budgettransactlinkid"]
        }
        return new Promise((resolve) => {
            return Vue.axios.post(
                '/rpc/upsert_json_budgettransact_v1',
                budgetData,
                {headers: authHeader()},
            ).then(
                result => {
                    commit(`${[Mutation.UpsertBudgetTransact]}`, result.data)
                    resolve()
                }
            ).catch(
                error => {throw new Error(`API ${error}`)}
            )
        })
    },
    [Action.FetchDistinctBudgetTransactTags]({commit}) {
        Vue.axios.get('budgettransacttag?select=*,tag(*)', {
            headers: authHeader(),
        }).then(result => {
            // TODO: distinct and reshape
            commit(`${[Mutation.DistinctBudgetTransactTags]}`, result.data)
        }).catch(error => {
            throw new Error(`API ${error}`)
        })
    },
}
