import Vue from 'vue';
import {Mutation} from './types';

export default {
    [Mutation.SaveSignUpSchema](state, payload) {
        Vue.set(state, 'signUpSchema', payload)
    },
    [Mutation.SaveSignUpForm](state, {result, payload}) {
        // TODO: save a history somewhere...?
        Vue.set(state, 'completed', true)
        Vue.set(state, 'submittedFormResponse', result)
        Vue.set(state, 'submittedForm', payload)
    },
    [Mutation.ClearSubmittedResponse](state) {
        Vue.set(state, 'completed', false)
        Vue.set(state, 'submittedFormResponse', {})
    }
}
