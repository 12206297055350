export const Action = {
    Login: "Login",
    Logout: "Logout",
    CheckLoggedIn: "CheckLoggedIn",
}

export const Mutation = {
    LoggedIn: "LoggedIn",
}

export const Getter = {
    IsLoggedIn: "IsLoggedIn",
}
