export const Action = {
    UpsertTransact: "UpsertTransact",
    CategoryInstanceList: "CategoryInstanceList",
    TransactList: "TransactList",
    ExploreTransactList: "ExploreTransactList",
    ActivateTransact: "ActivateTransact",
    ClearActiveTransact: "ClearActiveTransact",
    CompleteActiveTransact: "CompleteActiveTransact",
    DeleteTransact: "DeleteTransact",
    DistinctTransactTags: "DistinctTransactTags",
    TransactNameList: "TransactNameList",
}

export const Mutation = {
    UpsertTransact: "UpsertTransact",
    SetUpsertTransactRunning: "SetUpsertTransactRunning",
    CategoryInstanceList: "CategoryInstanceList",
    TransactList: "TransactList",
    SetTransactListRunning: "SetTransactListRunning",
    ExploreTransactList: "ExploreTransactList",
    SetExploreTransactListRunning: "SetExploreTransactListRunning",
    ActivateTransact: "ActivateTransact",
    ClearActiveTransact: "ClearActiveTransact",
    CompleteActiveTransact: "CompleteActiveTransact",
    DeleteTransact: "DeleteTransact",
    DistinctTransactTags: "DistinctTransactTags",
    TransactNameList: "TransactNameList",
}

export const Getter = {
    GetUpsertTransactRunning: "GetUpsertTransactRunning",
    GetPositiveCategoryInstanceList: "GetPositiveCategoryInstanceList",
    GetNegativeCategoryInstanceList: "GetNegativeCategoryInstanceList",
    GetAllCategoryInstanceList: "GetAllCategoryInstanceList",
    GetTransactList: "GetTransactList",
    GetTransactListRunning: "GetTransactListRunning",
    GetExploreTransactList: "GetExploreTransactList",
    GetExploreTransactListRunning: "GetExploreTransactListRunning",
    GetActiveTransact: "GetActiveTransact",
    GetActiveTransactCompleted: "GetActiveTransactCompleted",
    GetDistinctTransactTags: "GetDistinctTransactTags",
    GetTransactNameList: "GetTransactNameList",
}
