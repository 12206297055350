export default {
  activeFinpic: {finpicid: null, name: null, description: null},
  finpicList: [],
  finpicProfile: {
    finpicid: null,
    defaultaccount: null,
    externalaccount: null,
    externalbudgetcategoryinstance: null,
  },
  finpicProfileAlt: {
    monthlyBudget: null,
    templateInstance: null,
  },
  userProfile: {
    profileid: null,
    userid: null,
    defaultaccount: null,
    defaultfinpic: null,
  },
}
