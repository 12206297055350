import Vue from 'vue';
import {Mutation} from './types';

export default {
    [Mutation.BudgetTransactList](state, payload) {
        Vue.set(state, 'budgetTransactList', payload)
    },
    [Mutation.UpsertBudgetTransact](state, payload) {
        Vue.set(state, 'upsertResult', payload)
    },
    [Mutation.DistinctBudgetTransactTags](state, payload) {
        Vue.set(state, 'distinctBudgetTransactTags', payload)
    },
}
