<template>
    <div>
        <div v-if="!loggedInInternal">
          <v-btn
            to="/signup"
            text
          >
            <span class="mr-2">Sign Up</span>
          </v-btn>
          <v-btn
            to="/login"
            text
          >
            <span class="mr-2">Login</span>
          </v-btn>
        </div>
        <div v-else>
            <v-btn
                to="/profile"
                text
            >
                <span class="mr-2">Profile</span>
            </v-btn>
            <v-btn
                to="/logout"
                text
            >
                <span class="mr-2">Logout</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import {Getter} from '@/store/session/types'
export default {
    name: 'LoginButton',
    beforeUpdate() {
      this.$emit('check')
    },
    created() {
      this.$emit('check')
    },
  props: {
    showSignUp: {
      type: Boolean,
      default: true,
    },
    loggedIn: {
      type: Function,
      default: function() {
        return this.$store.getters[`${[Getter.IsLoggedIn]}`]
      }
    }
  },
    computed: {
        loggedInInternal() {
            return this.loggedIn()
        },
    },
    methods: {
    },
}
</script>
