import {Action, Mutation} from './types';
import Vue from "vue";
import {authHeader} from "@/store/headers";

export default {
    [Action.ApiSpec]({commit}){
        Vue.axios.get('/', {headers: authHeader(),}).then(
            result => {
                commit(`${[Mutation.ApiSpec]}`, result.data)
            }
        ).catch(
            error => {
                throw new Error(`API ${error}`)
            }
        )
    },
}
