export const Action = {
    LoadSignUpSchema: "LoadSignUpSchema",
    SaveSignUpForm: "SaveSignUpForm",
    ClearSubmittedResponse: "ClearSubmittedResponse",
}

export const Mutation = {
    SaveSignUpSchema: "SaveSignUpSchema",
    SaveSignUpForm: "SaveSignUpForm",
    ClearSubmittedResponse: "ClearSubmittedResponse",
}

export const Getter = {
    GetSignUpSchema: "GetSignUpSchema",
    GetSavedSignUpForm: "GetSavedSignUpForm",
    GetSubmittedResponse: "GetSubmittedResponse",
    GetCompleted: "GetCompleted",
    GetSuccessful: "GetSuccessful",
}
