import Vue from 'vue';
import {singleObjectAcceptHeader, authHeader} from '../headers';
import {Action, Mutation} from './types'
import {renameAllKeys, convertTransact, getBaseURL} from "@/store/helpers";
import {PostgrestClient} from "@supabase/postgrest-js";

// TODO: a way to tell when complete...? errors...?

const REST_URL = getBaseURL("/__api__/model/").replace(/\/$/, '')

const options = {
    headers: authHeader()
}
console.log(REST_URL)
let PG_URL = REST_URL
if (PG_URL === "/__api__/model") {
    PG_URL = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + PG_URL
}
console.log(PG_URL)
// TODO: this URL stuff (relative, absolute, fully qualified, etc.) is terrible
// postgrest requires a fully formed URL (not relative)
const postgrest = new PostgrestClient(PG_URL, options)

function getTransact(postgrest) {
    return postgrest
        .from('transact')
        .select(`
                transactid,
                transactdate,
                name,
                description,
                budgettransactlink(budgettransactlinkid, transactid),
                primarytransactattrlink!inner(
                    transactattrlink!inner(
                        innertransactattr:transactattr!inner!fk_transactattrlink_innertransactattr(
                            accountid,
                            amount,
                            postdate,
                            account!fk_transactattr_accountid(
                                accountid,
                                name
                            )
                        ),
                        outertransactattr:transactattr!inner!fk_transactattrlink_outertransactattr(
                            amount,
                            postdate,
                            account!fk_transactattr_accountid(
                                accountid,
                                name
                            )
                        )
                    )
                )
            `)
}

export default {
    [Action.UpsertTransact](
        {commit},
        {
            id = null, transactid = null, name, description = null, date,
            fromAccount, toAccount, amount, datePost,
            transactAttrLinkId = null,
            primarytransactattrlink = null,
            transactdate = null,
            budgets,
            tags,
            finpicid,
        }
        ) {
        console.log('firing!')
        console.log(budgets)
        commit(`${[Mutation.SetUpsertTransactRunning]}`, {status: true, result: 'running'})
        // TODO: a bit of a hack that needs cleanup
        // we really should be using a "type" to force these values to be sane
        if (!id) {
            if (transactid) {
                id = transactid
            }
        }
        if (!transactAttrLinkId) {
            if (primarytransactattrlink) {
                transactAttrLinkId = primarytransactattrlink[0].primarytransactattrlinkid
            }
        }
        if (!date) {
            if (transactdate) {
                date = transactdate
            }
        }
        // check for object inputs
        if (fromAccount === Object(fromAccount)) {
            fromAccount = fromAccount.accountid
        }
        if (toAccount === Object(toAccount)) {
            toAccount = toAccount.accountid
        }
        // force amounts to be negative (from) and positive (to)
        // TODO: enforce server side...?
        let submitData = {
            transactid: id,
            name: name,
            description: description,
            transactdate: date,
            inneraccountid: fromAccount,
            inneramount: -1 * Math.abs(amount),
            outeraccountid: toAccount,
            outeramount: Math.abs(amount),

            tags: tags,
            primarytransactattrlinkid: transactAttrLinkId,
            innerpostdate: datePost,
            outerpostdate: datePost,
        }
        if (!submitData['transactid']) delete submitData['transactid']
        if (!submitData['primarytransactattrlinkid']) delete submitData['primarytransactattrlinkid']
        return new Promise((resolve) => {
            return Vue.axios.post(
                '/rpc/upsert_json_transact_v1',
                submitData,
                {
                    headers: {'Accept': singleObjectAcceptHeader, ...authHeader()},
                }).then(
                result => {
                    console.log(result.data)
                    let transactid = result.data.transactid
                    console.log(transactid)
                    commit(`${[Mutation.UpsertTransact]}`, result.data)
                    if (budgets) {
                        if (budgets.length === 0) {
                            // ensure budget transacts are deleted
                            return Vue.axios.post(
                                'rpc/deletebudgettransacts',
                                {"_transactid": transactid},
                                {headers: authHeader()}
                            ).then(
                                () => {
                                    console.log('really done!')
                                    commit(`${[Mutation.SetUpsertTransactRunning]}`, {status: false, result: 'success'})
                                    resolve(transactid)
                                }
                            )
                        } else {
                            return Promise.all(
                                [
                                    ...budgets.map(
                                        (budget) => {
                                            let budgetData = {
                                                fromtransactid: transactid,
                                                frombudgetcategoryinstanceid: budget.fromCategoryInstance,
                                                fromamount: -Math.abs(budget.amount),
                                                fromdescription: budget.description,
                                                totransactid: transactid,
                                                tobudgetcategoryinstanceid: budget.toCategoryInstance,
                                                toamount: Math.abs(budget.amount),
                                                todescription: budget.description,
                                                finpicid: finpicid,
                                                budgettransactlinkid: budget.budgetTransactLinkId,
                                                tags: budget.tags,
                                            }
                                            if (!budget.budgetTransactLinkId) {
                                                delete budgetData["budgettransactlinkid"]
                                            }
                                            return Vue.axios.post(
                                                '/rpc/upsert_json_budgettransact_v1',
                                                budgetData,
                                                {headers: authHeader()},
                                            )
                                        }),
                                    // make sure missing budgets are removed...
                                    new Promise((res) => {
                                        // only fire if all provided budgets have a transactlinkid
                                        // otherwise could have a race condition with new budgets
                                        // TODO: better solution is to do everything server side
                                        // as-is we have a weird state issue if a budgettransact is added _and_ removed...
                                        let links = budgets.map((item) => {return item.budgetTransactLinkId})
                                        let linkIdMissing = budgets.filter((item) => {return !item.budgetTransactLinkId})
                                        if (linkIdMissing.length > 0) {
                                            console.log('skipping because missing link ids: ' + linkIdMissing)
                                            // doing nothing... missing budgets will not get deleted
                                            res()
                                        } else {
                                            let checkPayload = {
                                                _transactid: transactid,
                                                _budgetlinkids: links
                                            }
                                            return Vue.axios.post(
                                                "/rpc/remove_missing_budgets",
                                                checkPayload,
                                                {headers: authHeader()},
                                            ).then(
                                                () => {res()}
                                            )
                                        }
                                    })
                            ]).then(
                                () => {
                                    console.log('really done!')
                                    commit(`${[Mutation.SetUpsertTransactRunning]}`, {status: false, result: 'success'})
                                    resolve(transactid)
                                }
                            )
                        }
                    }
                    commit(`${[Mutation.SetUpsertTransactRunning]}`, {status: false, result: 'success'})
                    resolve(transactid)
                }
            ).catch(
                error => {
                    commit(`${[Mutation.SetUpsertTransactRunning]}`, {status: false, result: 'error'})
                    throw new Error(`API ${error}`)
                }
            )
        })
    },
    [Action.CategoryInstanceList]({commit}, {instanceid, instancename, includeGlobal=true}) {
        // TODO: improve after https://github.com/PostgREST/postgrest/issues/1075
        // Vue.axios.get('detail?detailtype.detailgroup.detailgrouptypeid=eq.1&detailtype.income=is.true&select=*,detailtype!fk_detail_finpic_detailtype(*,detailgroup(*)),detailinstance(*)').then(
        Vue.axios.get('detail?select=*,detailtype!fk_detail_finpic_detailtype(*,detailgroup(*)),detailinstance(*)', {
            headers: authHeader(),
        }).then(
            result => {
                const data = renameAllKeys(result.data.filter(
                    // filter client side
                    (x) => {
                        let prefilter = true;
                        if (instanceid) {
                            prefilter = prefilter && x.detailinstanceid === instanceid
                        }
                        if (instancename) {
                            prefilter = prefilter && x.detailinstance.name === instancename
                        }
                        if (includeGlobal) {
                            prefilter = prefilter || (x.detailtype.detailgroup.detailgrouptypeid !== 1)
                        }
                        return (
                            prefilter
                        )
                    }
                ))
                commit(`${[Mutation.CategoryInstanceList]}`, data)
            }
        ).catch(error => {
            throw new Error(`API ${error}`)
        })
    },
    [Action.ClearActiveTransact]({commit}) {
        commit(`${[Mutation.ClearActiveTransact]}`)
    },
    [Action.CompleteActiveTransact]({commit}) {
        commit(`${[Mutation.CompleteActiveTransact]}`)
    },
    [Action.ActivateTransact]({commit}, {transactid}) {
        console.log('activating transact')
        commit(`${[Mutation.ClearActiveTransact]}`)
        Vue.axios.get(
            `transact?transactid=eq.${transactid}&select=*,transacttag(*, tag(*)),primarytransactattrlink(*,transactattrlink(*,innertransactattr:transactattr!fk_transactattrlink_innertransactattr(*, account!fk_transactattr_accountid(*)),outertransactattr:transactattr!fk_transactattrlink_outertransactattr(*, account!fk_transactattr_accountid(*)))), budgets:transactdetaillink(*,budgettransacttag(*, tag(*)),fromtransactdetail:transactdetail!fk_transactdetaillink_finpic_transactdetail_from(*, detail(*, detailinstance(*), detailtype!fk_detail_finpic_detailtype(*, detailgroup(*)))), totransactdetail:transactdetail!fk_transactdetaillink_finpic_transactdetail_to(*, detail(*, detailinstance(*), detailtype!fk_detail_finpic_detailtype(*, detailgroup(*))))))`,
            {
                headers: {
                    'Accept': singleObjectAcceptHeader,
                    ...authHeader(),
                }
            }
    ).then(
            result => {
                // TODO: "guessTransactType" has a BIG dependence on "external"
                let outputData = convertTransact(result.data)
                commit(`${[Mutation.ActivateTransact]}`, outputData)
            }
        )
    },
    [Action.DeleteTransact]({commit}, {transactid}) {
        return new Promise((resolve) => {
            return Vue.axios.post(
                "rpc/deletetransact",
                {"_transactid": transactid},
                {headers: authHeader()},
            ).then(
                result => {
                    commit(`${[Mutation.DeleteTransact]}`, result.data)
                    resolve()
                }
            ).catch(
                error => {throw new Error(`API ${error}`)}
            )
        })
    },
    [Action.TransactList](
        {commit},
        {transactid, uploadfileid, accountid}
    ) {
        commit(`${[Mutation.SetTransactListRunning]}`, true)
        let query_params = ''
        if (uploadfileid) {
            query_params = '&uploadfilerecordtransact.uploadfilerecord.uploadfileid=eq.' + uploadfileid
        } else if (transactid) {
            query_params = '&transactid=eq.' + transactid
        } else if (accountid) {
            // only pass accountid if NOT passing uploadfileid, because it is used as a filter, not for "value fixing" server side
            // TODO: figure out if we want to "fix negative values" server side, and how we differentiate with filtering
            query_params = '&or=(primarytransactattrlink.transactattrlink.innertransactattr.accountid.eq.' + accountid + ',primarytransactattrlink.transactattrlink.outertransactattr.accountid.eq.' + accountid
        }

        // TODO: return a proper promise...
        Vue.axios.get(
            // 'transact?select=*,uploadfilerecordtransact(*,uploadfilerecord(*,uploadfile(*))),primarytransactattrlink(*,transactattrlink(*,innertransactattr:transactattr!fk_transactattrlink_innertransactattr(*,account!fk_transactattr_accountid(*)),outertransactattr:transactattr!fk_transactattrlink_outertransactattr(*,account!fk_transactattr_accountid(*)))), budgets:transactdetaillink(*,budgettransacttag(*, tag(*)),fromtransactdetail:transactdetail!fk_transactdetaillink_finpic_transactdetail_from(*, detail(*, detailinstance(*), detailtype!fk_detail_finpic_detailtype(*, detailgroup(*)))), totransactdetail:transactdetail!fk_transactdetaillink_finpic_transactdetail_to(*, detail(*, detailinstance(*), detailtype!fk_detail_finpic_detailtype(*, detailgroup(*))))))',
            'transact?select=*,uploadfilerecordtransact!inner(*,uploadfilerecord!inner(*,uploadfile(uploadfileid,userid,filename,created,createdby,lastmodified,lastmodifiedby,md5))),primarytransactattrlink!inner(*,transactattrlink!inner(*,innertransactattr:transactattr!fk_transactattrlink_innertransactattr(*,account!fk_transactattr_accountid(*)),outertransactattr:transactattr!fk_transactattrlink_outertransactattr(*,account!fk_transactattr_accountid(*)))), budgets:transactdetaillink(*,budgettransacttag(*, tag(*)),fromtransactdetail:transactdetail!fk_transactdetaillink_finpic_transactdetail_from(*, detail(*, detailinstance(*), detailtype!fk_detail_finpic_detailtype(*, detailgroup(*)))), totransactdetail:transactdetail!fk_transactdetaillink_finpic_transactdetail_to(*, detail(*, detailinstance(*), detailtype!fk_detail_finpic_detailtype(*, detailgroup(*))))))' + query_params,
            // 'rpc/tmp_get_transact',
            {headers: authHeader()},
        ).then(
            result => {
                let dat = result.data.filter(
                        (tr) => {
                            console.log("Gametime!")
                            let keepRecord = true
                            if (transactid) {
                                keepRecord = keepRecord && tr.transactid === transactid
                            }
                            console.log("Filtering")
                            console.log(uploadfileid)
                            if (uploadfileid) {
                                console.log("Really filtering")
                                let anyMatches = false
                                if (tr) {
                                    if (tr.uploadfilerecordtransact) {
                                        anyMatches = tr.uploadfilerecordtransact.some(
                                            (elt) => {
                                                console.log("Checking")
                                                console.log(elt)
                                                if (elt.uploadfilerecord) {
                                                    return elt.uploadfilerecord.uploadfileid === uploadfileid
                                                }
                                                return false
                                            }
                                        )
                                    }
                                }
                                keepRecord = keepRecord && anyMatches
                            }
                            return keepRecord
                        }
                    )
                console.log(dat)
                console.log("lessee")
                dat = dat.map(
                    (elt) => {
                        // console.log(elt)
                        let new_elt = convertTransact(elt)
                        // console.log(new_elt)

                        // fixing values / turning amounts positive or negative
                        if (accountid) {
                            if (new_elt.primarytransactattrlink[0].transactattrlink.innertransactattr.accountid == accountid) {
                                // console.log("Changing amount to: " + (-1 * new_elt.amount).toString())
                                new_elt.amount = -1 * new_elt.amount
                            }
                        }
                        return new_elt
                    }
                )
                commit(`${[Mutation.SetTransactListRunning]}`, false)
                commit(`${[Mutation.TransactList]}`, dat)
            }
        ).catch(
            (error) => {
                commit(`${[Mutation.SetTransactListRunning]}`, false)
                throw new Error(`API ${error}`)
            }
        )
    },
    [Action.ExploreTransactList]({commit}, {accountid}) {
        commit(`${[Mutation.SetExploreTransactListRunning]}`, true)
        return Promise.all([
            getTransact(postgrest)
                .eq(
                    'primarytransactattrlink.transactattrlink.innertransactattr.accountid',
                    accountid
                ),
            getTransact(postgrest)
                .eq(
                    'primarytransactattrlink.transactattrlink.outertransactattr.accountid',
                    accountid
                )
        ])
            // TODO: detect if there are duplicates on transactid...?
            .then((res) => {
                let dat = [].concat(res[0].data, res[1].data)
                dat = dat.map(
                    (elt) => {
                        let new_elt = convertTransact(elt)
                        // fixing values / turning amounts positive or negative
                        if (new_elt.primarytransactattrlink[0].transactattrlink.innertransactattr.accountid === accountid) {
                            // console.log("Changing amount to: " + (-1 * new_elt.amount).toString())
                            new_elt.amount = -1 * new_elt.amount
                        }
                        return new_elt
                    }
                )
                commit(`${[Mutation.ExploreTransactList]}`, dat)
                commit(`${[Mutation.SetExploreTransactListRunning]}`, false)
            }).catch((err) => {
                commit(`${[Mutation.SetExploreTransactListRunning]}`, false)
                console.log(err)
            })
    },
    [Action.DistinctTransactTags]({commit}) {
        Vue.axios.get('transacttag?select=tag(*),*', {
            headers: authHeader(),
        }).then(result => {
            // TODO: distinct and reshape
            commit(`${[Mutation.DistinctTransactTags]}`, result.data)
        }).catch(error => {
            throw new Error(`API ${error}`)
        })
    },
    [Action.TransactNameList]({commit}) {
        // TODO: a way to do this server-side... PERF
        // TODO: a way to cache somehow...?
        Vue.axios.get('transact?select=name', {
            headers: authHeader(),
        }).then(result => {
            commit(`${[Mutation.TransactNameList]}`, result.data)
        }).catch(error => {
            throw new Error(`API ${error}`)
        })
    },
}
