import {Mutation} from './types.js'
import Vue from 'vue'

export default {
    [Mutation.saveAccountList](state, payload) {
        Vue.set(state, 'accountList', payload)
    },
    [Mutation.saveBankList](state, payload) {
        Vue.set(state, 'bankList', payload)
    },
    [Mutation.saveAccountTypeList](state, payload) {
        Vue.set(state, 'accountTypeList', payload)
    }
}
