<template>
    <v-container fluid>
        <v-row dense>
             <v-col
               v-for="link in links"
               :key="link.title"
               :cols="link.cols"
               :md="link.md"
             >
                     <v-hover v-slot="{ hover }">
                     <v-card
                       :to="link.url"
                       :elevation="hover ? 12 : 2"
                       :class="{ 'on-hover': hover }"
                       :height="cardHeight"
                       style="text-align:center;padding: 10px;"
                     >
                         <v-img :height="itemHeight" width="auto" v-if="type === 'image'">
                         </v-img>
                         <v-icon :height="itemHeight" width="auto" v-if="type === 'icon'" style="font-size:60px">
                             {{link.icon}}
                         </v-icon>
                         <v-card-title v-text="link.title" v-if="type === 'image'"></v-card-title>
                         <v-card-title v-text="link.title" style="text-align:center;display:block;" v-if="type === 'icon'"></v-card-title>
                     </v-card>
                     </v-hover>
             </v-col>
        </v-row>
    </v-container>
</template>

<script>
// TODO: router-links above (with `to`) key, should be made more flexible... how do you handle external links...?
export default {
    name: 'ActionButtons',
    props: {
        type: {
            type: String,
            default: "image",
        },
        itemHeight: {
            type: String,
            default: "200px",
        },
        cardHeight: {
            type: String,
            default: "auto"
        },
        links: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    data: () => ({
    }),
}
</script>
