import {Action, Mutation, Getter} from './types'
import Vue from "vue";
import {authHeader, singleObjectAcceptHeader} from "@/store/headers";

export default {
    [Action.loadFinpicProfile]({commit}, {finpicid}) {
        return new Promise(
            (resolve) => {
                return Promise.all([
                    Vue.axios.get('finpicprofile?finpicid=eq.' + finpicid, {
                        headers: {
                            'Accept': singleObjectAcceptHeader,
                            ...authHeader(),
                        }
                    }),
                    Vue.axios.get("detailgroup?detailgrouptypeid=eq.1&finpicid=eq." + finpicid + "&select=detailgroupid", {
                        headers: {
                            'Accept': singleObjectAcceptHeader,
                            ...authHeader(),
                        }
                    }),
                    Vue.axios.get("detailinstance?name=eq.Template&finpicid=eq." + finpicid + "&select=detailinstanceid", {
                        headers: {
                            'Accept': singleObjectAcceptHeader,
                            ...authHeader(),
                        }
                    })
                ]).then(result => {
                    const altData = {
                        monthlyBudget: result[1].data.detailgroupid,
                        templateInstance: result[2].data.detailinstanceid
                    }
                    console.log(altData)
                    commit(
                        `${[Mutation.saveFinpicProfile]}`,
                        {newData: result[0].data, altData: altData}
                    )
                    resolve()
                }).catch(error => {
                    // TODO: our error catching for batched requests like this needs to be more savvy
                    // in this case, an unrelated error is killing the finpicprofile working properly!!
                    resolve()
                    throw new Error(`API ${error}`)
                })
            }
        )
    },
    [Action.loadUserProfile]({commit, dispatch, getters}) {
        return new Promise((resolve) => {
            // make sure to get just the first record...
            return Vue.axios.get('profile', {
                headers: {
                    'Accept': singleObjectAcceptHeader,
                    ...authHeader(),
                }
            }).then(result => {
                commit(`${[Mutation.saveUserProfile]}`, result.data)

                // if finpicid not set already, set to default
                const finpic = getters[`${[Getter.getActiveFinpic]}`]
                // do not change the finpicid
                if (!finpic?.finpicid) {
                    let res = dispatch(`${[Action.setActiveFinpic]}`, result.data.defaultfinpic)
                    res.then(() => {
                        // save profile
                        commit(`${[Mutation.saveUserProfile]}`, result.data)
                        resolve()
                    })
                } else {
                    // save profile
                    resolve()
                }
            }).catch(error => {
                resolve()
                throw new Error(`API ${error}`)
            })
        })
    },
    [Action.setActiveFinpic]({commit, dispatch}, finpicid) {
        return new Promise(
            (resolve) => {
                return Vue.axios.get('finpic?finpicid=eq.' + finpicid, {
                    headers: {
                        'Accept': singleObjectAcceptHeader,
                        ...authHeader(),
                    }
                }).then(result => {
                    commit(`${[Mutation.saveActiveFinpic]}`, result.data)
                    let res = dispatch(`${Action.loadFinpicProfile}`, {finpicid: finpicid})
                    res.then(() => {resolve()})
                }).catch(error => {
                    resolve()
                    throw new Error(`API ${error}`)
                })
            }
        )
    },
    [Action.listFinpic]({commit}) {
        Vue.axios.get('finpic', {
            headers: {
                ...authHeader()
            }
        }).then(result => {
            commit(`${[Mutation.saveFinpicList]}`, result.data)
        }).catch(error => {
            throw new Error(`API ${error}`)
        })
    },
}
