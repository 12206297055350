import {Getter} from './types';
export default {
    [Getter.GetSummary](state) {
        return state.summary;
    },
    [Getter.GetMonthSummary](state) {
        return state.monthSummary
    },
    [Getter.GetMonthSummaryRunning](state) {
        return state.monthSummaryRunning
    },
    [Getter.GetBudgetCategoryData](state) {
        return state.budgetCategoryData
    },
    [Getter.GetBudgetCategoryDataRunning](state) {
        return state.budgetCategoryDataRunning
    },
}
