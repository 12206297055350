export const Action = {
    FetchMonthlyInstanceList: 'FetchMonthlyInstanceList',
    SetActiveInstance: 'SetActiveInstance',
    SyncInstanceTemplate: 'SyncInstanceTemplate',
    CreateInstance: 'CreateInstance',
    CreateInstanceFromTemplate: 'CreateInstanceFromTemplate',
}

export const Mutation = {
    SaveMonthlyInstanceList: 'SaveMonthlyInstanceList',
    SaveActiveInstance: 'SaveActiveInstance',
}

export const Getter = {
    GetMonthlyInstanceList: 'GetMonthlyInstanceList',
    GetActiveInstance: 'GetActiveInstance',
}
