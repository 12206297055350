import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

// TODO: switch to sass for smaller bundle size
export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.green.darken2,
                secondary: colors.green.lighten3,
                accent: colors.green.accent1,
            }
        }
    }
});
