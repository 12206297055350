export const Action = {
    FetchSavingCategories: 'FetchSavingCategories',
    FetchGlobalInstance: 'FetchGlobalInstance',
    FetchSavingBudget: 'FetchSavingBudget',
    FetchMonthlyCategoryList: 'FetchMonthlyCategoryList',
    DeleteMonthlyCategoryInstance: 'DeleteMonthlyCategoryInstance',
    UpsertMonthlyCategoryInstance: 'UpsertMonthlyCategoryInstance',
}

export const Mutation = {
    SaveSavingCategories: 'SaveSavingCategories',
    SaveGlobalInstance: 'SaveGlobalInstance',
    SaveSavingBudget: 'SaveSavingBudget',
    SaveMonthlyCategoryList: 'SaveMonthlyCategoryList',
}

export const Getter = {
    GetSavingCategories: 'GetSavingCategories',
    GetGlobalInstance: 'GetGlobalInstance',
    GetSavingBudget: 'GetSavingBudget',
    GetMonthlyCategoryList: 'GetMonthlyCategoryList',
}
