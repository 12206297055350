<template>
  <v-app>
    <v-navigation-drawer
      app
      temporary
      :mini-variant.sync="mini"
      v-model="drawer"
     >
      <v-list nav>
        <v-list-item to="/">
          <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
          <v-list-item-title>HOME</v-list-item-title>
        </v-list-item>
        <v-list-group :value="true">
          <template v-slot:activator>
            <v-list-item-title>LEARN</v-list-item-title>
          </template>
          <v-list-item to="/learn">Blog</v-list-item>
          <v-list-item to="/tools">Tools</v-list-item>
        </v-list-group>
        <v-list-group :value="true">
          <template v-slot:activator>
            <v-list-item-title>SETUP</v-list-item-title>
          </template>
          <v-list-item to="/plan">Plan</v-list-item>
          <v-list-item to="/save">Save</v-list-item>
          <v-list-item to="/account">Accounts</v-list-item>
        </v-list-group>
        <v-list-group :value="true">
          <template v-slot:activator>
            <v-list-item-title>BUDGET</v-list-item-title>
          </template>
          <v-list-item to="/import">Import</v-list-item>
          <v-list-item to="/summary">Summary</v-list-item>
          <v-list-item to="/review">Review</v-list-item>
        </v-list-group>
        <v-list-group :value="true">
          <template v-slot:activator>
            <v-list-item-title>OTHER</v-list-item-title>
          </template>
          <v-list-item to="/explore">Explore</v-list-item>
          <v-list-item to="/profile">Profile</v-list-item>
        </v-list-group>
        <div style="height:200px"></div>
      </v-list>
    </v-navigation-drawer>


    <v-app-bar
      app
      color="primary"
      dark
    >
      <a class="d-flex align-center" @click="showDrawer">
        <v-btn text>
          <v-icon large>
            mdi-menu
          </v-icon>
        </v-btn>
        <!-- TODO: Logo
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
        -->
      </a>

      <v-btn
        to="/"
        text
      >
        <span class="mr-2">Home</span>
      </v-btn>

      <v-btn
          to="/about"
          text
      >
        <span class="mr-2">About</span>
      </v-btn>
      <v-btn
        to="/learn"
        text
      >
        <span class="mr-2">Learn</span>
      </v-btn>

      <v-btn
        to="/budget"
        text
      >
        <span class="mr-2">Budget</span>
      </v-btn>

      <v-btn
        to="/review"
        text
      >
        <span class="mr-2">Review</span>
      </v-btn>

      <v-spacer></v-spacer>

      <LoginButton
          v-on:check="checkLoggedIn"
      ></LoginButton>

      <!-- TODO
      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      -->
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LoginButton from '@/components/LoginButton';
import {Action} from '@/store/session/types'
import {Action as ProfileAction} from '@/store/profile/types'

export default {
  name: 'App',
  metaInfo: {
    title: '',
    titleTemplate: '%s | Budget ICU'
  },

  components: {
    LoginButton,
  },

  mounted() {
    this.checkLoggedIn()
    this.$store.dispatch(`${[ProfileAction.loadUserProfile]}`)
  },

  methods: {
    showDrawer: function() {
      this.drawer = true
    },
    checkLoggedIn: function() {
      this.$store.dispatch(`${[Action.CheckLoggedIn]}`)
    }
  },

  data: () => ({
    drawer: false,
    mini: false,
  }),
};
</script>

<style scoped>
.v-overlay__scrim , .v-list-group__items .theme--light {
  margin-left: 20px;
}
</style>
