export default {
  uploadFiles: [],
  uploadFileSuccess: false,
  matchOptions: [],
  uploadFileMatchRunning: false,
  matchUploadFileRecordsRunning: false,
  matches: {},
  activeUploadFileId: null,
  activeAccountId: null,
  discardMatchRunning: false,
}
