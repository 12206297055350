import Vue from 'vue';
import {Mutation} from './types';

export default {
    [Mutation.UpsertTransact](state, payload) {
        Vue.set(state, 'transact', payload)
    },
    [Mutation.SetUpsertTransactRunning](state, payload) {
        Vue.set(state, 'upsertTransactRunning', payload)
    },
    [Mutation.CategoryInstanceList](state, payload) {
        Vue.set(state, 'positiveCategoryInstanceList', payload.filter(
            (x) => {
                return (x.budgetcategory.budget.budgettypeid === 1 && x.budgetcategory.income) ||
                x.budgetcategory.budget.budgettypeid !== 1
            }
        ))
        Vue.set(state, 'negativeCategoryInstanceList', payload.filter(
            (x) => {
                return (x.budgetcategory.budget.budgettypeid === 1 && ! x.budgetcategory.income) ||
                    x.budgetcategory.budget.budgettypeid !== 1}
        ))
        Vue.set(state, 'allCategoryInstanceList', payload)
    },
    [Mutation.ClearActiveTransact](state) {
        Vue.set(state, 'activeTransactCompleted', false)
    },
    [Mutation.CompleteActiveTransact](state) {
        Vue.set(state, 'activeTransactCompleted', true)
    },
    [Mutation.ActivateTransact](state, payload) {
        Vue.set(state, 'activeTransact', payload)
        Vue.set(state, 'activeTransactCompleted', true)
    },
    [Mutation.TransactList](state, payload) {
        Vue.set(state, 'transactList', payload)
    },
    [Mutation.SetTransactListRunning](state, payload) {
        Vue.set(state, 'transactListRunning', payload)
    },
    [Mutation.ExploreTransactList](state, payload) {
        Vue.set(state, 'exploreTransactList', payload)
    },
    [Mutation.SetExploreTransactListRunning](state, payload) {
        Vue.set(state, 'exploreTransactListRunning', payload)
    },
    [Mutation.DeleteTransact](state, payload) {
        Vue.set(state, 'deletedTransact', payload)
    },
    [Mutation.DistinctTransactTags](state, payload) {
        Vue.set(state, 'distinctTransactTags', payload)
    },
    [Mutation.TransactNameList](state, payload) {
        Vue.set(state, 'transactNameList', payload)
    },
}
