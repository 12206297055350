export const Action = {
    setActiveFinpic: 'setActiveFinpic',
    loadFinpicProfile: 'loadFinpicProfile',
    loadUserProfile: 'loadUserProfile',
    listFinpic: 'listFinpic',
}

export const Mutation = {
    saveActiveFinpic: 'saveActiveFinpic',
    saveFinpicProfile: 'saveFinpicProfile',
    saveUserProfile: 'saveUserProfile',
    saveFinpicList: 'saveFinpicList',
}

export const Getter = {
    getActiveFinpic: 'getActiveFinpic',
    getFinpicList: 'getFinpicList',
    getUserProfile: 'getUserProfile',
    getFinpicProfile: 'getFinpicProfile',
    getFinpicProfileAlt: 'getFinpicProfileAlt',
}
