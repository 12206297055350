import {Action, Mutation} from './types';
import {Getter as ProfileGetter} from "@/store/profile/types";
import Vue from "vue";
import {authHeader, singleObjectAcceptHeader} from "@/store/headers";
import {renameAllKeys} from "@/store/helpers";
import {Action as BudgetAction} from "@/store/budgetTransact/types";
import {Action as BudgetCategoryAction} from '@/store/budgetCategory/types'

export default {
    [Action.SetActiveInstance]({commit, dispatch, getters}, {instanceid, instancename}) {
        let queryStr = '';
        // instanceid takes precedence...
        // TODO: warn / error if both?
        if (instancename) {
            queryStr = 'name=eq.' + instancename
        }
        if (instanceid) {
            queryStr = 'detailinstanceid=eq.' + instanceid
        }
        const finpic = getters[`${[ProfileGetter.getActiveFinpic]}`]
        const finpicid = finpic.finpicid
        if (finpicid) {
            queryStr = queryStr + (queryStr === '' ? '' : '&') + 'finpicid=eq.' + finpicid
        }
        Vue.axios.get(
            'detailinstance?select=*&' + queryStr,
            {
                headers: {
                    'Accept': singleObjectAcceptHeader,
                    ...authHeader(),
                },
            }).then(
            (result) => {
                let instanceData = renameAllKeys(result.data)
                commit(`${[Mutation.SaveActiveInstance]}`, instanceData)
                dispatch(`${[BudgetAction.BudgetTransactList]}`, {instanceid: instanceData.instanceid})
            }
        )
    },
    [Action.FetchMonthlyInstanceList]({commit, dispatch}, {activate, finpicid}) {
        Vue.axios.get('detailinstance?finpicid=eq.' + finpicid + '&order=effective.asc', {
            headers: authHeader(),
        }).then(result => {
            const rawdat = renameAllKeys(result.data)
            const returndat = rawdat.filter(
                (inst) => {
                    return inst.name !== 'Global' && inst.name !== 'Template'
                }
            )
            if (activate === 'latest') {
                dispatch(`${[BudgetCategoryAction.FetchMonthlyCategoryList]}`, {instanceid: returndat[returndat.length - 1].instanceid})
            }
            commit(`${[Mutation.SaveMonthlyInstanceList]}`, returndat)
        }).catch(error => {
            throw new Error(`API ${error}`)
        })
    },
    [Action.SyncInstanceTemplate]({dispatch}, {templateid, instanceid}) {
        Vue.axios.post(
            '/rpc/sync_instance_template_v1',
            {templateid: templateid, targetid: instanceid},
            {headers: authHeader()}
        ).then(() => {
            // presume that the "instanceid" being passed is active
            dispatch([BudgetCategoryAction.FetchMonthlyCategoryList], {instanceid: instanceid})
        }).catch(error => {
            throw new Error(`API ${error}`)
        })
    },
    [Action.CreateInstance]({dispatch}, {finpicid, input_type, input_date}) {
        const postData = {
            finpicid: finpicid,
            input_type: input_type,
            input_date: input_date
        };
        if (!input_type) {
            delete postData['input_type']
        }
        if (!input_date) delete postData['input_date']
        Vue.axios.post(
            '/rpc/create_instance',
            postData,
            {headers: {'Accept': singleObjectAcceptHeader, ...authHeader()}},
        ).then(result => {
            const renamedData = renameAllKeys(result.data)
            dispatch(`${[Action.FetchMonthlyInstanceList]}`, {})
            dispatch(`${[BudgetCategoryAction.FetchMonthlyCategoryList]}`, {instanceid: renamedData.instanceid},)
        }).catch(error => {
            throw new Error(`API ${error}`)
        })
    },
    // TODO: redundant... a way to share code? Literally two line different...
    [Action.CreateInstanceFromTemplate]({dispatch}, {finpicid, input_type, input_date, templateid}) {
        const postData = {
            finpicid: finpicid,
            input_type: input_type,
            input_date: input_date
        };
        if (!input_type) {
            delete postData['input_type']
        }
        if (!input_date) delete postData['input_date']
        Vue.axios.post(
            '/rpc/create_instance',
            postData,
            {headers: {'Accept': singleObjectAcceptHeader, ...authHeader()}}
        ).then(result => {
            const renamedData = renameAllKeys(result.data)
            dispatch(`${[Action.SyncInstanceTemplate]}`, {templateid: templateid, instanceid: renamedData.instanceid})
            dispatch(`${[Action.FetchMonthlyInstanceList]}`, {})
            dispatch(`${[BudgetCategoryAction.FetchMonthlyCategoryList]}`, {instanceid: renamedData.instanceid})
        }).catch(error => {
            throw new Error(`API ${error}`)
        })
    },
}
