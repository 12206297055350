export const Action = {
    ApiSpec: "ApiSpec",
}

export const Mutation = {
    ApiSpec: "ApiSpec",
}

export const Getter = {
    GetApiSpec: "GetApiSpec",
}
