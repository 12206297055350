import {Mutation} from './types'
import Vue from "vue";

export default {
    [Mutation.SaveSavingCategories](state, payload) {
        Vue.set(state, 'savingCategories', payload)
    },
    [Mutation.SaveGlobalInstance](state, payload) {
        Vue.set(state, 'globalInstance', payload)
    },
    [Mutation.SaveSavingBudget](state, payload) {
        Vue.set(state, 'savingBudget', payload)
    },
    [Mutation.SaveMonthlyCategoryList](state, payload) {
        Vue.set(state, 'monthlyCategoryList', payload)
    },
}
