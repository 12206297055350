import {Action, Mutation} from './types';
import Vue from 'vue'
import {authHeader} from "@/store/headers";
import {renameAllKeys} from "@/store/helpers";
import * as aq from 'arquero'
import * as f from '@colearendt/financial'
export default {
    [Action.FetchSummary]({commit}) {
        return new Promise(resolve => {
            Vue.axios.post(
                '/rpc/budgetcategoryinstance_summary',
                {},
                {headers: authHeader()}
            ).then(
                result => {
                    Promise.all(
                        [
                            Vue.axios.get(
                                'detailinstance?select=*',
                                {headers: authHeader()},
                            ),
                            Vue.axios.get(
                                'detail?select=*,detailtype!fk_detail_finpic_detailtype(*,detailgroup!fk_detailtype_finpic_detailgroup(*,detailgrouptype(*)))',
                                {headers: authHeader()}
                            )
                        ]
                    ).then(
                        res => {
                            // TODO: catch bad statuses...?
                            let inst = renameAllKeys(res[0].data)
                            let category = renameAllKeys(res[1].data)
                            let summary = result.data

                            inst = aq.from(inst)
                            category = aq.from(category)
                            summary = aq.from(summary)
                            let summary2 = summary.join(
                                    inst, 'instanceid',
                                    [aq.all(), aq.not('finpicid', 'instanceid')]
                                )
                            console.log(summary2)
                            let summary3 = summary2.join(
                                    category, 'budgetcategoryinstanceid',
                                    [aq.all(), aq.not('finpicid', 'statusid', 'budgetcategoryinstanceid', 'instanceid', 'name')]
                                )
                            console.log(summary3)

                            commit(`${[Mutation.SetSummary]}`, summary3)
                            resolve()
                        }
                    )
                }
            ).catch(
                error => {
                    resolve()
                    throw new Error(`API ${error}`)
                }
            )
        })
    },
    [Action.FetchMonthSummary]({commit}) {
        // clear out data before fetching
        commit(`${[Mutation.SetMonthSummary]}`, [])
        commit(`${[Mutation.SetMonthSummaryRunning]}`, true)
        return new Promise(resolve => {
            Vue.axios.post(
                '/rpc/month_summary',
                {},
                {headers: authHeader()}
            ).then(
                result => {
                    Promise.all(
                        [
                            Vue.axios.get(
                                'detailinstance?select=*',
                                {headers: authHeader()},
                            ),
                            Vue.axios.get(
                                'detail?select=*,detailtype!fk_detail_finpic_detailtype(*,detailgroup!fk_detailtype_finpic_detailgroup(*,detailgrouptype(*)))',
                                {headers: authHeader()}
                            )
                        ]
                    ).then(
                        res => {
                            // TODO: catch bad statuses...?
                            let inst = renameAllKeys(res[0].data)
                            let category = renameAllKeys(res[1].data)
                            let summary = result.data

                            inst = aq.from(inst)
                            category = aq.from(category)
                            summary = aq.from(summary)
                            let summary2 = summary.join(
                                inst, 'instanceid',
                                [aq.all(), aq.not('finpicid', 'instanceid')]
                            )
                            console.log(summary2)
                            let summary3 = summary2.join(
                                category, 'budgetcategoryinstanceid',
                                [aq.all(), aq.not('finpicid', 'statusid', 'budgetcategoryinstanceid', 'instanceid', 'name')]
                            )
                            console.log(summary3)

                            commit(`${[Mutation.SetMonthSummary]}`, summary3)
                            commit(`${[Mutation.SetMonthSummaryRunning]}`, false)
                            resolve()
                        }
                    )
                }
            ).catch(
                error => {
                    commit(`${[Mutation.SetMonthSummaryRunning]}`, false)
                    resolve()
                    throw new Error(`API ${error}`)
                }
            )
        })
    },
    [Action.FetchBudgetCategoryData]({commit}, {budgetcategoryid, month}) {
        // clear out data before fetching...
        commit(`${[Mutation.SetBudgetCategoryData]}`, [])
        commit(`${[Mutation.SetBudgetCategoryDataRunning]}`, true)
        let prep = f.strToDate(month + " 00:00:00")
        let prep2 = f.computeRelativeMonth(prep, 1)
        let prep3 = f.dateToStr(prep2)
        let nextMonth = prep3.substring(0,10)
        return new Promise((resolve) => {
            return Promise.all([
                Vue.axios.get(
                    `transactdetail?select=*,detail!inner(*,detailinstance!inner(*)),transact!fk_transactdetail_transactid!inner(*)&detail.detailinstance.or=(name.eq.Global,name.ilike.*Year*)&transact.transactdate=lt.${nextMonth}&transact.transactdate=gte.${month}&detail.detailtypeid=eq.${budgetcategoryid}`,
                    {headers: authHeader()}
                ),
                Vue.axios.get(
                    `transactdetail?select=*,detail!inner(*,detailinstance!inner(*)),transact!fk_transactdetail_transactid!inner(*)&detail.detailinstance.effective=eq.${month}&detail.detailtypeid=eq.${budgetcategoryid}`,
                    {headers: authHeader()}
                ),
            ]).then(result => {
                let prep = [].concat(result[0].data, result[1].data)
                let outputData = renameAllKeys(prep)
                commit(`${[Mutation.SetBudgetCategoryData]}`, outputData)
                commit(`${[Mutation.SetBudgetCategoryDataRunning]}`, false)
                resolve()
            }).catch(error => {
                commit(`${[Mutation.SetBudgetCategoryDataRunning]}`, false)
                resolve()
                throw new Error(`API ${error}`)
            })
        })
    },
}
