export default {
    transact: null,
    upsertTransactRunning: {status: false, result: null},
    positiveCategoryInstanceList: [],
    negativeCategoryInstanceList: [],
    allCategoryInstanceList: [],
    transactList: [],
    transactListRunning: false,
    exploreTransactList: [],
    exploreTransactListRunning: false,
    counter: 0,
    activeTransact: {},
    activeTransactCompleted: true,
    deletedTransact: {},
    distinctTransactTags: [],
    transactNameList: [],
}
