import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import './plugins/apexcharts'
import router from './router';
import store from '@/store/store';

import VueMatomo from 'vue-matomo'

Vue.config.productionTip = false

Vue.use(VueMatomo, {
  host: 'https://www.budget.icu',
  siteId: 2,
  router: router,
  enableLinkTracking: true,
  requireConsent: false, // TODO: want to do it
  trackInitialView: true,
  disableCookies: false, // TODO: want to do it
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  debug: false,
  domains: '*.budget.icu'
})

new Vue({
  vuetify,
  router,
  render: h => h(App),
  store: store
}).$mount('#app')
