import Vue from 'vue'
import {Action, Mutation} from './types.js'
import {authHeader, singleObjectAcceptHeader} from '../headers'

export default {
    [Action.loadAccountList]({commit}, {finpicid}) {
        Vue.axios.get('accountfinpic?select=*,account(*, bank(*), accounttype(*))', {
            headers: authHeader(),
        }).then(
            result => {
                // TODO: make finpicid required... or get it off of profile?
                // Need finpicid to ensure uniquness
                let res = result.data
                if (finpicid) {
                    res = res.filter(
                        (x) => {return x.finpicid === finpicid}
                    )
                }
                // restructure to remove finpicid tags
                res = res.map(
                    (x) => {return x.account}
                )
                commit(`${[Mutation.saveAccountList]}`, res)
            }).catch(error => {
            throw new Error(`API ${error}`)
        })
    },
    [Action.loadBankList]({commit}) {
        Vue.axios.get(
            'bank?select=*',
            {headers: authHeader()}
        ).then(
            result => {
                commit(`${[Mutation.saveBankList]}`, result.data)
            }
        ).catch(
            error => {
                throw new Error(`API ${error}`)
            }
        )
    },
    [Action.loadAccountTypeList]({commit}) {
        Vue.axios.get(
            'accounttype?select=*',
            {headers: authHeader()}
        ).then(
                result => {
                    commit(`${[Mutation.saveAccountTypeList]}`, result.data)
                }
        ).catch(
            error => {
                throw new Error(`API ${error}`)
            }
        )
    },
    [Action.upsertAccount]({dispatch}, {accountid, name, shortname, bankid, accounttypeid, finpicid}) {
        let submitData = {
            accountid: accountid,
            name: name,
            shortname: shortname,
            bankid: bankid,
            accounttypeid: accounttypeid,
        }
        if (!accountid) {
            delete submitData['accountid']
        }
        Vue.axios.post(
            'account',
            submitData,
        {headers: {
                'Accept': singleObjectAcceptHeader,
                'Prefer': 'resolution=merge-duplicates,return=representation',
        ...authHeader(),
        }}
    ).then(
            (result) => {
                Vue.axios.post(
                    'accountfinpic?on_conflict=accountid,finpicid',
                    {
                        accountid: result.data.accountid,
                        finpicid: finpicid,
                    },
                    {headers: {
                        'Accept': singleObjectAcceptHeader,
                        'Prefer': 'resolution=merge-duplicates,return=representation',
                        ...authHeader()
                        }}
                ).then(
                    () => {
                        dispatch(`${[Action.loadAccountList]}`, {finpicid: finpicid})
                    }
                ).catch(
                    (error) => {
                        throw new Error(`API ${error}`)
                    }
                )
            }
        ).catch(
            (error) => {
                throw new Error(`API ${error}`)
            }
        )
    }
}
