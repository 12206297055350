export function getBaseURL(suffix) {
    let host = process.env.VUE_APP_API_HOST
    let outputURL = suffix
    if (host) {
        outputURL = host + "/" + suffix
        // remove "double slashes" (but not for https://)
        let re = /([^:])\/\//g
        outputURL = outputURL.replaceAll(re, '$1/')
    }
    return outputURL
}

// TODO - need better performance!!
export function renameAllKeys(arr) {
    // TODO: careful of before/after naming collisions
    if (Array.isArray(arr)) {
        for (let i = 0; i < arr.length; i++) {
            arr[i] = renameKeys(arr[i])
        }
    } else {
        arr = renameKeys(arr)
    }
    return(arr)
}

function renameKeys(obj) {
    let lookupMap = {
        'detailid': 'budgetcategoryinstanceid',
        'detail': 'budgetcategoryinstance',
        'detailgrouptypeid': 'budgettypeid',
        'detailgrouptype': 'budgettype',
        'detailgroupid': 'budgetid',
        'detailgroup': 'budget',
        'detailtypeid': 'budgetcategoryid',
        'detailtype': 'budgetcategory',
        'detailinstanceid': 'instanceid',
        'detailinstance': 'instance',
        'typeorder': 'order',
        'transactdetailid': 'budgettransactid',
        'transactdetaillinkid': 'budgettransactlinkid',
        'budget': 'amount',
    }
    for (const objKey in obj) {
        // if an object, recurse
        if (typeof(obj[objKey]) === 'object' && obj[objKey] !== null && !Array.isArray(obj[objKey])) {
            obj[objKey] = renameKeys(obj[objKey])
        }
        // if an array, recurse
        if (Array.isArray(obj[objKey])) {
            obj[objKey] = renameAllKeys(obj[objKey])
        }
        // then rename
        if (lookupMap[objKey]) {
            obj[lookupMap[objKey]] = obj[objKey]
            delete obj[objKey]
        }
    }
    return obj
}

export function convertTransact(transact) {
    let outputData = renameAllKeys(transact)
    // force positive. We will correct the sign when we submit back to the database
    outputData.amount = Math.abs(outputData.primarytransactattrlink[0].transactattrlink.innertransactattr.amount)
    // convert budgets from data model to the UI model we are using... (flatten it a bit...)
    outputData.budgets = convertBudgets(outputData.budgets)
    outputData.tags = convertTransactTags(outputData.transacttag)
    outputData.date = outputData.transactdate
    if (outputData.primarytransactattrlink[0].transactattrlink.innertransactattr.account && outputData.primarytransactattrlink[0].transactattrlink.outertransactattr.account) {
        // TODO: finalize this data model... implement it in a view or something server side...?
        outputData.fromAccount = outputData.primarytransactattrlink[0].transactattrlink.innertransactattr.account
        outputData.toAccount = outputData.primarytransactattrlink[0].transactattrlink.outertransactattr.account
        outputData.transactType = guessTransactType(
            outputData.primarytransactattrlink[0].transactattrlink.innertransactattr.account.accounttypeid,
            outputData.primarytransactattrlink[0].transactattrlink.outertransactattr.account.accounttypeid,
        )
    } else {
        console.log("ERROR: could not find account information")
        console.log(transact)
        console.log(outputData)
    }
    return outputData
}

export function convertBudgets(budgets) {
    if (budgets) {
        for (let i = 0; i < budgets.length; i++) {
            budgets[i] = convertBudget(budgets[i])
        }
    }
    return budgets
}

function convertBudget(budget) {
    // TODO: get the database model (or what is returned from postgrest)
    // to be more like what we use within the application
    // The modifications here are where we have a discrepancy...
    // It basically amounts to us considering a transactdetaillink a known / complete entity
    // or a generic link between two objects
    let outputBudget = {}
    outputBudget.description = budget.fromtransactdetail.description
    // sign will be fixed when we send it back to the database
    outputBudget.amount = Math.abs(budget.fromtransactdetail.amount)
    outputBudget.fromCategoryInstance = budget.fromtransactdetail.budgetcategoryinstance.budgetcategoryinstanceid
    outputBudget.toCategoryInstance = budget.totransactdetail.budgetcategoryinstance.budgetcategoryinstanceid
    outputBudget.fromBudgetCategoryInstanceId = budget.fromtransactdetailid
    outputBudget.toBudgetCategoryInstanceId = budget.totransactdetailid
    outputBudget.budgetTransactLinkId = budget.budgettransactlinkid
    outputBudget.tags = convertTransactTags(budget.budgettransacttag)
    return outputBudget
}

export function guessTransactType(innerTransactAttrAccountTypeId, outerTransactAttrAccountTypeId) {
    if (innerTransactAttrAccountTypeId === 0) {
        if (outerTransactAttrAccountTypeId === 0) {
            // both external - a transfer / placeholder
            return 2
        } else {
            // income. From external to something else
            return 1
        }
    } else {
        if (outerTransactAttrAccountTypeId === 0) {
            // purchase. From something to external
            return 0
        } else {
            // transfer. neither external
            return 2
        }
    }
}

export function convertTransactTags(tagArray) {
    if (tagArray) {
        return tagArray.map(
            (x) => {
                return x.tag.value
            }
        )
    }
    return []
}
